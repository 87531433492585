import axios from "axios";

export default class Public {
  findActiveRegEvent() {
    return axios.get("events/active");
  }

  loadFractions(isShowAll = false) {
    if (!isShowAll) return axios.get("fractions");

    return axios.get("fractions?show_all=true");
  }

  loadSuits() {
    return axios.get("suits");
  }

  findCity(query) {
    return axios.get("city", {
      params: {
        filter: query
      }
    });
  }

  findCommand(query) {
    return axios.get("command", {
      params: {
        filter: query
      }
    });
  }

  isCallsignUnique(value) {
    return axios.get("members/isfree/callsign", {
      params: { callsign: value }
    });
  }

  isEmailUnique(value) {
    return axios.get("members/isfree/email", { params: { email: value } });
  }

  createMember(data) {
    return axios.post("register", data);
  }

  uploadImages(data) {
    return axios.post("images/uploadPhotos", data);
  }

  scanCode(value) {
    return axios.get("scan", { params: { data: value } });
  }

  findLocations(query = "") {
    return axios.get("location", {
      params: {
        filter: query
      }
    });
  }
}
