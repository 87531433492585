// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import "./polyfill";
// import cssVars from 'css-vars-ponyfill'
import Vue from "vue";
import "es6-promise/auto";
import Vuex from "vuex";
import BootstrapVue from "bootstrap-vue";
import App from "./App";
import router from "./router/index";
import Vuelidate from "vuelidate";
import VueLazyload from "vue-lazyload";
import VueNotifications from "vue-notifications";
import miniToastr from "mini-toastr";
import Vue2Storage from "vue2-storage";
import ImageUploader from "vue-image-upload-resize";
import VueQuillEditor from "vue-quill-editor";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import * as VueGoogleMaps from "vue2-google-maps";
import API from "./api/stalk.net";

// todo
// cssVars()

// const prod = process.env.NODE_ENV === 'production';
// const shouldSW = 'serviceWorker' in navigator && prod;
// const shouldSWDev = 'serviceWorker' in navigator && !prod;

// if (shouldSW) {
//   navigator.serviceWorker.register('/js/service-worker.js').then(() => {
//     console.log("Service Worker Registered!");
//   })
// } else if (shouldSWDev) {
//   navigator.serviceWorker.register('/js/service-worker-dev.js').then(() => {
//     console.log('Service Worker Registered!')
//   })
// }

Vue.use(Vuex);
Vue.use(BootstrapVue);
Vue.use(Vuelidate);
Vue.use(VueLazyload);
Vue.use(Vue2Storage, {
  prefix: "app_",
  driver: "memory",
  ttl: 60 * 60 * 8 * 1000
});
Vue.use(ImageUploader);
Vue.use(VueQuillEditor);
Vue.use(VueGoogleMaps, {
  load: {
    key: "AIzaSyB2RlG6EMJQanq_Wjs9Cn6G_elbfY59CGU"
  },
  // Demonstrating how we can customize the name of the components
  installComponents: true
});

miniToastr.init({
  types: {
    success: "success",
    error: "error",
    info: "info",
    warn: "warn"
  }
});

let resolver; //TODO workaround resolver
let rejecter; //TODO workaround rejecter
const store = new Vuex.Store({
  state: {
    user: null,
    member: null,
    dataLoading: new Promise((resolve, reject) => {
      resolver = resolve;
      rejecter = reject;
    })
  },
  mutations: {
    setUser(user, newUser) {
      this.state.user = newUser;
    },
    setMember(member, newMember) {
      this.state.member = newMember;
    },
    doneLoading() {
      resolver();
    },
    errorLoading(dataLoading, e) {
      rejecter(e);
    }
  },
  actions: {
    async loadUser() {
      let self = this;

      try {
        const resp = await API.private.getSelfUserInfo();
        self.commit("setUser", resp.data);
      } catch (e) {
        self.commit("setUser", null);
        self.commit("errorLoading", e);
      }
    },
    async loadMember() {
      let self = this;

      try {
        if (!self.state.user)
          throw { e: { response: { data: "ERR_NOT_ALLOWED" } } };
        const resp = await API.private.getMemberInfo(self.state.user.Id);
        self.commit("setMember", resp.data);
        self.commit("doneLoading");
      } catch (e) {
        self.commit("setMember", null);
        self.commit("errorLoading", e);
      }
    }
  }
});

function toast({ title, message, type, timeout, cb }) {
  let msg = message;
  if (
    typeof message === "object" &&
    typeof message.data !== "undefined" &&
    typeof message.data.error !== "undefined"
  ) {
    //replace output for typed messages
    switch (message.data.error) {
      case "ERR_SERVICE":
        msg = "Ошибка сервиса";
        break;
      case "ERR_NOT_FOUND":
        msg = "Не найдено";
        break;
      case "ERR_ALREADY_EXISTS":
        msg = "Запись существует";
        break;
      case "ERR_ALREADY_ENABLED":
        msg = "Уже активно";
        break;
      case "ERR_ALREADY_DISABLED":
        msg = "Уже неактивно";
        break;
      case "ERR_BAD_REQUEST":
        msg = "Неверный запрос";
        break;
      case "ERR_BAD_PARAM":
        msg = "Неверный параметр";
        break;
      case "ERR_NOT_ALLOWED":
        msg = "Действие не разрешено";
        break;
      case "ERR_BAD_JWT":
        msg = "Неверный JWT-токен";
        break;
      case "ERR_BALANCE_NOT_ENOUGH":
        msg = "Недостаточно денег";
        break;
      case "ERR_BAD_AUTH":
        msg = "Ошибка авторизации";
        break;
      case "ERR_BAD_SIGNATURE":
        msg = "Неверная подпись";
        break;
      default:
        msg = message.data.error;
    }
    if (typeof message.data.value !== "undefined") {
      msg += ": " + message.data.value;
    }
    if (typeof message.data.description !== "undefined") {
      msg += " " + message.data.description;
    }
  }
  return miniToastr[type](msg, title, timeout, cb);
}

Vue.use(VueNotifications, {
  success: toast,
  error: toast,
  info: toast,
  warn: toast
});

Vue.filter("formatDate", value => {
  return new Date(value * 1000).toLocaleDateString("ru-RU");
});
Vue.filter("formatDateTime", value => {
  const d = new Date(value * 1000);
  return d.toLocaleDateString("ru-RU") + " " + d.toLocaleTimeString("ru-RU");
});
Vue.filter("formatCurrency", function(value) {
  if (typeof value !== "number") {
    return value;
  }
  let formatter = new Intl.NumberFormat("ru-RU", {
    style: "currency",
    currency: "UAH",
    minimumFractionDigits: 0
  });
  return formatter.format(value);
});
Vue.filter("limitString", value => {
  const maxSymbols = 10;
  if (typeof value === "string" && value.length > maxSymbols) {
    value = value.slice(0, maxSymbols) + "...";
  }
  return value;
});

/* eslint-disable no-new */
new Vue({
  el: "#app",
  store,
  router,
  template: "<App/>",
  components: {
    App
  }
});
