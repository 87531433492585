import axios from "axios";

export default class Private {
  errorResponseHandler(error) {
    console.log("error");
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      console.log(error.response.data);
      console.log(error.response.status);
      console.log(error.response.headers);
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
      // http.ClientRequest in node.js
      console.log(error.request);
    } else {
      // Something happened in setting up the request that triggered an Error
      console.log("Error", error.message);
    }
    console.log(error.config);
    return Promise.reject(error);
  }

  init() {
    axios.defaults.headers.common["Authorization"] =
      "Bearer " + localStorage.getItem("access_token");

    // apply interceptor on response
    // axios.interceptors.response.use(
    //   response => response,
    //   self.errorResponseHandler
    // );
  }

  getSelfUserInfo() {
    return axios.get("user");
  }

  getMemberInfo() {
    return axios.get("member/user" );
  }

  getActiveMemberInfo(id) {
    return axios.get("member/user/" + id);
  }

  createMemberInfo() {
    return axios.post("member/");
  }

  getEventRequests() {
    return axios.get("event/requests");
  }

  getUserByRequest(id) {
    return axios.get("/request/user/" + id);
  }

  getMemberByRequest(id) {
    return axios.get("/request/member/" + id);
  }

  getRequestById(id) {
    return axios.get("/request/" + id);
  }

  getNotificationById(id) {
    return axios.get("/change_log/" + id);
  }

  getUserActions() {
    return axios.get("/actions");
  }

  logout() {
    //TODO realise this (clean token in back-end)
    localStorage.clear();
    axios.defaults.headers.common["Authorization"] = "";
    return Promise.resolve();
  }

  confirmLookForRequest(rid) {
    return axios.post("/request/approve_look/" + rid, { is_look_ok: true });
  }

  confirmLegendForRequest(rid) {
    return axios.post("/request/approve_legend/" + rid, { is_legend_ok: true });
  }

  approveRequest(rid, data) {
    return axios.post("/request/approve/" + rid, data);
  }

  commentRequest(rid, data) {
    return axios.post("/request/comment/" + rid, data);
  }

  rejectRequest(rid, data) {
    return axios.post("/request/reject/" + rid, data);
  }

  payedRequest(rid, data) {
    return axios.post("/request/payed/" + rid, data);
  }

  getMembers() {
    return axios.get("member");
  }

  getItems() {
    return axios.get("item");
  }

  createOrUpdateItem(data) {
    return axios.post("item", data);
  }

  getItemById(id) {
    return axios.get("/item/" + id);
  }

  passportGenerate(mid) {
    return axios.post("/member/passport/" + mid);
  }

  characterCreate(data) {
    return axios.post("/member", data);
  }

  getStaff() {
    return axios.get("member?group=gamemaster&group=admin");
  }

  getNotifications() {
    return axios.get(
      "/change_log"
    );
  }

  getNewNotificationsCount() {
    return axios.get(
      "/change_log_count"
    );
  }

  getFirmwares(type = "") {
    if (type !== "") {
      return axios.get("firmware/" + type);
    }
    return axios.get("firmware");
  }

  addNewFirmware(type, data) {
    const formData = new FormData();
    for (let key in data) {
      formData.append(key, data[key]);
    }
    return axios.post("firmware/" + type, formData);
  }

  createOrUpdateLocation(data) {
    return axios.post("location", data);
  }

  getLocationById(id) {
    return axios.get("/location/" + id);
  }

  createOrUpdateGPS(data) {
    return axios.post("gps", data);
  }

  getMembersEventRequest(eventId, memberId) {
    return axios.get("/event/" + eventId + "/member/" + memberId);
  }

  sendMembersEventRequest(eventId, memberId, data) {
    return axios.post("/event/" + eventId + "/member/" + memberId, data);
  }

  getMembersByEvent(eventId, filters = null) {
    let url = "/event/" + eventId + "/member";
    if (filters) {
      let queryString = Object.keys(filters)
        .map(function(key) {
          return key + "=" + filters[key];
        })
        .join("&");
      if (queryString) {
        url += "?" + queryString;
      }
    }
    return axios.get(url);
  }

  updateMember(memberId, data) {
    return axios.post("/member/" + memberId, data);
  }
}
