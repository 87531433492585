import Vue from "vue";
import Router from "vue-router";

// Containers
const DefaultContainer = () => import("@/containers/DefaultContainer");
const ClearForm = () => import("@/containers/ClearForm");

// Views member
const Profile = () => import("@/views/member/Profile");
const ProfileEdit = () => import("@/views/member/ProfileEdit");
const ProfileCharacterCreate = () => import("@/views/member/ProfileCharacterCreate");
const Events = () => import("@/views/member/Events");
const Members = () => import("@/views/member/Members");

// View admin
const AdminDashboard = () => import("@/views/admin/Dashboard");
const AdminRequests = () => import("@/views/admin/Requests");
const AdminMembers = () => import("@/views/admin/Members");
const AdminRegistration = () => import("@/views/admin/Registration");
const AdminStaff = () => import("@/views/admin/Staff");
const AdminNotifications = () => import("@/views/admin/Notifications");
const AdminMessages = () => import("@/views/admin/Messages");
const AdminItemsList = () => import("@/views/admin/items/ItemsList");
const AdminItemEdit = () => import("@/views/admin/items/ItemEdit");

const AdminFirmwaresList = () =>
  import("@/views/admin/firmwares/FirmwaresList");
const AdminFirmwareAdd = () => import("@/views/admin/firmwares/FirmwareAdd");

const AdminLocationsList = () =>
  import("@/views/admin/locations/LocationsList");
const AdminLocationEdit = () => import("@/views/admin/locations/LocationEdit");

const AdminQuestsList = () => import("@/views/admin/quests/QuestsList");

// View pub
const Scan = () => import("@/views/Scan");
const Register = () => import("@/views/register/Register");
const Login = () => import("@/views/Login");
const PassRestore = () => import("@/views/PassRestore");
const PassChange = () => import("@/views/PassChange");

const Jupiter = () => import("@/views/special/Jupiter.vue");
const EWZones = () => import("@/views/EWZones.vue");

Vue.use(Router);

export default new Router({
  //mode: 'history',
  linkActiveClass: "open active",
  //scrollBehavior: () => ({y: 0}),
  routes: [
    {
      path: "/system",
      // name: 'StalkNet',
      component: DefaultContainer,
      children: [
        {
          path: "dashboard",
          name: "Dashboard",
          component: AdminDashboard
        },
        {
          path: "/",
          name: "Профиль",
          component: Profile
        },
        {
          path: "/edit/:id",
          name: "Редактирование профиля",
          component: ProfileEdit
        },
        {
          path: "/create",
          name: "Создание персонажа",
          component: ProfileCharacterCreate
        },
        {
          path: "events",
          name: "Мероприятия",
          component: Events
        },
        {
          path: "event/:id/members",
          name: "Участники",
          component: Members
        },
        {
          path: "admin/requests",
          name: "Заявки",
          component: AdminRequests
        },
        {
          path: "admin/members",
          name: "Заплатившие",
          component: AdminMembers
        },
        {
          path: "admin/register",
          name: "Регистрация",
          component: AdminRegistration
        },
        {
          path: "admin/staff",
          name: "Персонал",
          component: AdminStaff
        },
        {
          path: "admin/notifications",
          name: "Оповещения",
          component: AdminNotifications
        },
        {
          path: "admin/messages",
          name: "Сообщения",
          component: AdminMessages
        },
        {
          path: "admin/items",
          name: "Предметы",
          component: AdminItemsList
        },
        {
          path: "admin/items/edit/:id",
          name: "Редактирование предмета",
          component: AdminItemEdit
        },

        {
          path: "admin/firmwares",
          name: "Прошивки",
          component: AdminFirmwaresList
        },
        {
          path: "admin/firmwares/edit/:id",
          name: "Добавление прошивки",
          component: AdminFirmwareAdd
        },

        {
          path: "admin/locations",
          name: "Локации",
          component: AdminLocationsList
        },
        {
          path: "admin/locations/edit/:id",
          name: "Редактирование локации",
          component: AdminLocationEdit
        },

        {
          path: "admin/quests",
          name: "Задания",
          component: AdminQuestsList
        },

        {
          path: "special/jupiter",
          name: "Jupiter",
          component: Jupiter
        },
      ]
    },
    {
      path: "/",
      redirect: { name: "Login" },
      component: ClearForm,
      children: [
        {
          path: "scan",
          name: "Scan",
          component: Scan
        },
        {
          path: "register",
          name: "Register",
          component: Register
        },
        {
          path: "login",
          name: "Login",
          component: Login
        },
        {
          path: "restore",
          name: "Restore",
          component: PassRestore
        },
        {
          path: "restore/:uuid/:token",
          name: "Изменение пароля",
          component: PassChange
        },
        {
          path: "ewzones",
          name: "EWones",
          component: EWZones
        }
      ]
    }
  ]
});
