import axios from "axios";
import Public from "./public";
import Private from "./private";

axios.defaults.baseURL = process.env.VUE_APP_API_URL;
axios.defaults.headers.common["Authorization"] =
  "Bearer " + localStorage.getItem("access_token");

class API {
  constructor() {
    this.public = new Public();
    this.private = new Private();
    this.baseURL = axios.defaults.baseURL;

    this.consts = {
      Pda: {
        Own: "own",
        Rent: "rent",
        Purchase: "purchase",
        None: "none"
      },

      ShotLight: {
        Own: "own",
        Purchase: "purchase",
        None: "none"
      },
      NoCommand: "Внеком",

      Rank: {
        Novice: "novice",
        Experienced: "experienced",
        Veteran: "veteran",
        Master: "master",
        Legend: "legend"
      },

      Request: {
        Approved: "approved",
        Commented: "commented",
        Declined: "declined",
        PartiallyPaid: "partially_paid",
        Paid: "paid",
        None: "none"
      },

      PaymentType: {
        Cash: "cash",
        Combatant: "combatant",
        Free: "free",
        Cashless: "cashless",
        None: "none"
      },

      UserGroups: {
        Member: "member",
        GameMaster: "gamemaster",
        FractionLeader: "fraction_leader",
        Admin: "admin"
      },

      ItemTypes: {
        Suit: "suit",
        Artefact: "artefact",
        Quest: "quest",
        Other: "other"
      },

      ItemOwners: {
        Own: "own",
        Personal: "personal",
        Other: "other"
      },

      ItemPlaces: {
        Storage: "storage",
        Garage: "garage",
        Home: "home",
        Other: "other"
      },

      FirmwareTypes: {
        Unknown: "unknown",
        Artefact: "artefact",
        PdaSoul: "soul",
        Anomaly: "anomaly",
        Defence: "defence",
      },

      LocationTypes: {
        Main: "main",
        Quest: "quest",
        Other: "other"
      },

      LocationZones: {
        Red: "red",
        Yellow: "yellow",
        Green: "green",
        Blue: "blue"
      },

      ActionTypes: {
        Unknown: "unknown",
        Legend: "legend",
        LegendMaster: "legend_master",
        PassportPhoto: "passport_photo",
        Photos: "photos",
        CallSign: "callsign",
        Suit: "suit",
        Fraction: "fraction",
        NewRequest: "new_request"
      }
    };

    this.ranks = new Map([
      [this.consts.Rank.Novice, "Новичок"],
      [this.consts.Rank.Experienced, "Опытный"],
      [this.consts.Rank.Veteran, "Ветеран"],
      [this.consts.Rank.Master, "Мастер"],
      [this.consts.Rank.Legend, "Легенда"]
    ]);

    this.pdaVariants = new Map([
      [this.consts.Pda.Own, "Свой"],
      [this.consts.Pda.Rent, "Аренда"],
      [this.consts.Pda.Purchase, "Покупка"],
      [this.consts.Pda.None, "Нет"]
    ]);

    this.shotLightVariants = new Map([
      [this.consts.ShotLight.Own, "Свой"],
      [this.consts.ShotLight.Purchase, "Покупка"],
      [this.consts.ShotLight.None, "Нет"]
    ]);

    this.confirmVariants = new Map([
      [this.consts.Request.Approved, "Подтверждена"],
      [this.consts.Request.Commented, "Оставлен комментарий"],
      [this.consts.Request.Declined, "Отклонена"],
      [this.consts.Request.PartiallyPaid, "Частично оплачена"],
      [this.consts.Request.Paid, "Оплачена"],
      [this.consts.Request.None, "На рассмотрении"]
    ]);

    this.paymentTypeVariants = new Map([
      [this.consts.PaymentType.Cashless, "Безнал"],
      [this.consts.PaymentType.Cash, "Наличка"],
      [this.consts.PaymentType.Combatant, "УБД"],
      [this.consts.PaymentType.Free, "Бесплатно"],
      [this.consts.PaymentType.None, "Не платил"]
    ]);

    this.itemTypesVariants = new Map([
      [this.consts.ItemTypes.Suit, "Костюм"],
      [this.consts.ItemTypes.Artefact, "Артефакт"],
      [this.consts.ItemTypes.Quest, "Квестовый"],
      [this.consts.ItemTypes.Other, "Другое"]
    ]);

    this.itemOwnersVariants = new Map([
      [this.consts.ItemOwners.Own, "Клубный"],
      [this.consts.ItemOwners.Personal, "Личный"],
      [this.consts.ItemOwners.Other, "Чужой/Другое"]
    ]);

    this.itemPlacesVariants = new Map([
      [this.consts.ItemPlaces.Storage, "Склад"],
      [this.consts.ItemPlaces.Garage, "Гараж"],
      [this.consts.ItemPlaces.Home, "Дом"],
      [this.consts.ItemPlaces.Other, "Другое"]
    ]);

    this.firmwareTypes = new Map([
      [this.consts.FirmwareTypes.Unknown, "Неизвестно"],
      [this.consts.FirmwareTypes.Artefact, "Артефакт"],
      [this.consts.FirmwareTypes.PdaSoul, 'ПДА "Душа"'],
      [this.consts.FirmwareTypes.Anomaly, "Аномалия"],
      [this.consts.FirmwareTypes.Defence, "Незламність"]
    ]);

    this.locationTypes = new Map([
      [this.consts.LocationTypes.Main, "Основная"],
      [this.consts.LocationTypes.Quest, "Квестовая"],
      [this.consts.LocationTypes.Other, "Другая"]
    ]);

    this.locationZones = new Map([
      [this.consts.LocationZones.Red, "Красная"],
      [this.consts.LocationZones.Yellow, "Желтая"],
      [this.consts.LocationZones.Green, "Зеленая"],
      [this.consts.LocationZones.Blue, "Синяя"]
    ]);

    this.actionTypes = new Map([
      [this.consts.ActionTypes.Unknown, "Неизвестно"],
      [this.consts.ActionTypes.Legend, "Легенда"],
      [this.consts.ActionTypes.LegendMaster, "Игромастерская Легенда"],
      [this.consts.ActionTypes.PassportPhoto, "Фото на паспорт"],
      [this.consts.ActionTypes.Photos, "Фото"],
      [this.consts.ActionTypes.CallSign, "Позывной"],
      [this.consts.ActionTypes.Suit, "Костюм"],
      [this.consts.ActionTypes.Fraction, "Группировка"],
      [this.consts.ActionTypes.NewRequest, "Новая Заявка"]
    ]);

    this.actionTypesMessages = new Map([
      [this.consts.ActionTypes.Unknown, "Неизвестно"],
      [this.consts.ActionTypes.Legend, "Изменения в легенде"],
      [this.consts.ActionTypes.LegendMaster, "Игромастерская Легенда"],
      [this.consts.ActionTypes.PassportPhoto, "Изменено фото на паспорт"],
      [this.consts.ActionTypes.Photos, "Произведены изменения в фото"],
      [this.consts.ActionTypes.CallSign, "Изменен позывной"],
      [this.consts.ActionTypes.Suit, "Изменен костюм"],
      [this.consts.ActionTypes.Fraction, "Изменена группировка"],
      [this.consts.ActionTypes.NewRequest, "Добавлена новая заявка"]
    ]);

    this.commonYesNo = new Map([[true, "Да"], [false, "Нет"]]);
  }

  getRankTitle(rankCode) {
    return this.ranks.get(rankCode);
  }

  getConfirmTitle(confirmCode) {
    return this.confirmVariants.get(confirmCode);
  }

  getPaymentTypeTitle(paymentType) {
    return this.paymentTypeVariants.get(paymentType);
  }

  getPdaVariantTitle(getPdaVariantCode) {
    return this.pdaVariants.get(getPdaVariantCode);
  }

  getItemTypeTitle(type) {
    return this.itemTypesVariants.get(type);
  }

  getItemTypeVariants() {
    let res = [];
    this.itemTypesVariants.forEach((v, k) => {
      res.push({ text: v, value: k });
    });
    return res;
  }

  getItemOwnersTitle(type) {
    return this.itemOwnersVariants.get(type);
  }

  getItemOwnersVariants() {
    let res = [];
    this.itemOwnersVariants.forEach((v, k) => {
      res.push({ text: v, value: k });
    });
    return res;
  }

  getItemPlacesTitle(type) {
    return this.itemPlacesVariants.get(type);
  }

  getItemPlacesVariants() {
    let res = [];
    this.itemPlacesVariants.forEach((v, k) => {
      res.push({ text: v, value: k });
    });
    return res;
  }

  getFirmwareTypeTitle(firmwareTitle) {
    return this.firmwareTypes.get(firmwareTitle);
  }

  getFirmwaresTypeVariants() {
    let res = [];
    this.firmwareTypes.forEach((v, k) => {
      res.push({ text: v, value: k });
    });
    return res;
  }

  getLocationTypeTitle(title) {
    return this.locationTypes.get(title);
  }

  getLocationTypeVariants() {
    let res = [];
    this.locationTypes.forEach((v, k) => {
      res.push({ text: v, value: k });
    });
    return res;
  }

  getLocationZoneVariants() {
    let res = [];
    this.locationZones.forEach((v, k) => {
      res.push({ text: v, value: k });
    });
    return res;
  }

  getActionTypeTitle(actionType) {
    return this.actionTypes.get(actionType);
  }

  getActionTypeMessage(actionType) {
    return this.actionTypesMessages.get(actionType);
  }

  getCommonYesNo(value) {
    return this.commonYesNo.get(value);
  }

  login(data) {
    return axios
      .post("login", data)
      .then(res => {
        localStorage.setItem("access_token", res.data.access_token);
        localStorage.setItem("refresh_token", res.data.refresh_token);
        localStorage.setItem("user_group", res.data.user_group);

        this.private.init();
        return this.private.getUserActions();
      })
      .then(res => {
        localStorage.setItem("actions", res.data);
      });
  }

  restore(data) {
    return axios.post("restore", data);
  }

  changePassword(data) {
    return axios.post("users/passchange", data);
  }
}

export default new API(); //export as Singleton object
